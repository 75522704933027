import React, {useState, useCallback, Fragment} from 'react'
import {
    Button,
    LegacyStack,
    Icon,
    Modal,
    TextContainer,
    LegacyCard,
    Text,
    InlineGrid,
    Box,
    Card,
    RadioButton,
    BlockStack,
} from "@shopify/polaris"
import formatCurrency from '../../../../helpers/formatCurrency'
import { DeleteIcon, ChevronUpIcon,  ChevronDownIcon} from "@shopify/polaris-icons";
import CardDivider from '../../../../components/cardDivider';
import styled from 'styled-components'
import update from 'immutability-helper'
const ABTestingWrapper = styled.div`
    margin: 20px 0;
    .ab-analytics {
        margin: 0 10px;
    }
    .ab-tabs {
        margin-top: 10px;
        border-top: 1px solid #DFE3E8;
        border-bottom: 1px solid #DFE3E8;
        .version-item {
            text-align: center;
            padding: 15px 0;
            cursor: pointer;
            svg {
                fill: #D72C0D;
                width: 80%
                height: 80%
            }
        }
        .active {
            background: #F2F7FE;
            border-bottom: 2px solid #2C6ECB;

        }
        .b-version {
            background: #F1F8F5;
            border-bottom: 2px solid #008060;
        }
    }
`
const ButtonWrapper = styled.div`
    margin: 20px 0 0 0;
`
function ABTesting(props) {
    const {  versionAnalytics, versionData, setVersionData, checkoutOfferType, version, setVersion, state, setState, locale, currency } = props
    const [ ABTestingEdit, setABTestingEdit ] = useState(true);
    const [activeA, setActiveA] = useState(false);
    const [activeB, setActiveB] = useState(false);
    const handleAChange = () => setActiveA(!activeA)
    
    const handleBChange = () => setActiveB(!activeB)
    const activatorA = <div onClick={handleAChange}><Button icon={DeleteIcon} tone="critical"></Button></div>
    const activatorB = <div onClick={handleBChange}><Button icon={DeleteIcon} tone="critical"></Button></div>
    const handleVersionChange = (_checked, newValue) => {
            if(newValue === 'A'){
                if("B"===version){
                    const newStateData = {...state, ...versionData, versionB:{
                        layout: state.layout, banner: state.banner, message: state.message, title: state.title,
                        subtitle: state.subtitle, cta: state.cta, compareAtPrice: state.compareAtPrice, offerBackgroundColor: state.offerBackgroundColor ? state.offerBackgroundColor : null,
                        ctaBackgroundColor: state.ctaBackgroundColor, ctaTextColor: state.ctaTextColor, ctaButtonTextColor: state.ctaButtonTextColor, ctaButtonBorderColor: state.ctaButtonBorderColor,
                        widgetBorderColor: state.widgetBorderColor, css: state.css, buttonBorderRadius: state.buttonBorderRadius, widgetBorderRadius: state.widgetBorderRadius,
                        buttonBorder: state.buttonBorder, widgetBorder: state.widgetBorder, offerStarting: state.offerStarting, offerEnding: state.offerEnding,
                        product: state.product, visibility: state.visibility ? state.visibility : null, variantsTriggers: state.variantsTriggers,
                    }}
                    setState(newStateData)
                }
            }else{
                if("A"===version){
                    setVersionData({
                        layout: state.layout, banner: state.banner, message: state.message, title: state.title,
                        subtitle: state.subtitle, cta: state.cta, compareAtPrice: state.compareAtPrice, offerBackgroundColor: state.offerBackgroundColor ? state.offerBackgroundColor : null,
                        ctaBackgroundColor: state.ctaBackgroundColor, ctaTextColor: state.ctaTextColor, ctaButtonTextColor: state.ctaButtonTextColor, ctaButtonBorderColor: state.ctaButtonBorderColor,
                        widgetBorderColor: state.widgetBorderColor, css: state.css, buttonBorderRadius: state.buttonBorderRadius, widgetBorderRadius: state.widgetBorderRadius,
                        buttonBorder: state.buttonBorder, widgetBorder: state.widgetBorder, offerStarting: state.offerStarting, offerEnding: state.offerEnding,
                        product: state.product, visibility: state.visibility ? state.visibility: null, variantsTriggers: state.variantsTriggers,
                    })
                    const newStateData = {...state, ...{
                        layout: state.versionB.layout, banner: state.versionB.banner, message: state.versionB.message, title: state.versionB.title,
                        subtitle: state.versionB.subtitle, cta: state.versionB.cta, compareAtPrice: state.versionB.compareAtPrice, offerBackgroundColor: state.versionB.offerBackgroundColor ? state.versionB.offerBackgroundColor : null,
                        ctaBackgroundColor: state.versionB.ctaBackgroundColor, ctaTextColor: state.versionB.ctaTextColor, ctaButtonTextColor: state.versionB.ctaButtonTextColor, ctaButtonBorderColor: state.versionB.ctaButtonBorderColor,
                        widgetBorderColor: state.versionB.widgetBorderColor, css: state.versionB.css, buttonBorderRadius: state.versionB.buttonBorderRadius, widgetBorderRadius: state.versionB.widgetBorderRadius,
                        buttonBorder: state.versionB.buttonBorder, widgetBorder: state.versionB.widgetBorder, offerStarting: state.versionB.offerStarting, offerEnding: state.versionB.offerEnding,
                        product: state.versionB.product, visibility: state.versionB.visibility ? state.versionB.visibility: null, variantsTriggers: state.versionB.variantsTriggers,
                    }}
                    setState(newStateData)
                }

            }
            setVersion(newValue)
            
        }
    const enableABTesting = () => {
     
        setState(update(state, { versionB: { $set: {
            layout: state.layout, banner: state.banner, message: state.message, title: state.title,
            subtitle: state.subtitle, cta: state.cta, compareAtPrice: state.compareAtPrice, offerBackgroundColor: state.offerBackgroundColor ? state.offerBackgroundColor : null,
            ctaBackgroundColor: state.ctaBackgroundColor, ctaTextColor: state.ctaTextColor, ctaButtonTextColor: state.ctaButtonTextColor, ctaButtonBorderColor: state.ctaButtonBorderColor,
            widgetBorderColor: state.widgetBorderColor, css: state.css, buttonBorderRadius: state.buttonBorderRadius, widgetBorderRadius: state.widgetBorderRadius,
            buttonBorder: state.buttonBorder, widgetBorder: state.widgetBorder, offerStarting: state.offerStarting, offerEnding: state.offerEnding,
            product: state.product, visibility: state.visibility ? state.visibility : null, variantsTriggers: state.variantsTriggers,
        } }}))
        setVersion("A")
    }
    const removeVersionA = () => {
        if("A" === version){
            setState({...state, ...state.versionB, versionB: null})
        }
        if("B" === version){
            setState({...state, versionB: null})
        }
        setVersion(null)
    }
    const removeVersionB = () => {
        if("A" === version){
            setState({...state, versionB: null})
        }
        if("B" === version){
            setState({...state, ...versionData, versionB: null})
        }
        setVersion(null)
    }
    return (
        <Box paddingBlock="400">
        {ABTestingEdit ?
        (state.versionB ?
        <LegacyCard sectioned>
                <div
                onClick={() => setABTestingEdit(false)}
                >
                    <LegacyStack alignment="center">
                        <LegacyStack.Item fill>
                        <Text as="h2" variant="headingMd">A/B Version</Text>
                        <Text tone="subdued">
                            Impressions, Conversion Rate, Revenue
                        </Text>
                        </LegacyStack.Item>
                        <LegacyStack.Item>
                        <Icon source={ChevronUpIcon} />
                        </LegacyStack.Item>
                    </LegacyStack>
                </div>
                <CardDivider/>
                <Box paddingBlock="400">
                <InlineGrid gap="400" columns={3}>
                    <Card sectioned>
                        <Box paddingBlockEnd="100" borderBlockStartWidth="0" borderInlineStartWidth="0" borderInlineEndWidth="0" borderBlockEndWidth="025" borderStyle="dashed" width="auto">
                            <Text variant="headingSm" as="h6">Impressions</Text>
                        </Box>
                        <Box paddingBlockStart="400">
                            <Text as='p'>{versionAnalytics&&versionAnalytics[version]?versionAnalytics[version].totalImpressionCount:0}</Text>
                        </Box>
                    </Card>
                    <Card sectioned>
                        <Box paddingBlockEnd="100" borderBlockStartWidth="0" borderInlineStartWidth="0" borderInlineEndWidth="0" borderBlockEndWidth="025" borderStyle="dashed" width="auto">
                            <Text variant="headingSm" as="h6">Conversion rate</Text>
                        </Box>
                        <Box paddingBlockStart="400">
                            <Text as='p'>{versionAnalytics&&versionAnalytics[version]?versionAnalytics[version].conversion:'-%'}</Text>
                        </Box>
                    </Card>
                    <Card sectioned>
                        <Box paddingBlockEnd="100" borderBlockStartWidth="0" borderInlineStartWidth="0" borderInlineEndWidth="0" borderBlockEndWidth="025" borderStyle="dashed" width="auto">
                            <Text variant="headingSm" as="h6">Revenue</Text>
                        </Box>
                        <Box paddingBlockStart="400">
                            <Text as='p'>{versionAnalytics&&versionAnalytics[version]&&versionAnalytics[version].totalUpsoldRevenue?formatCurrency(versionAnalytics[version].totalUpsoldRevenue,locale,currency):formatCurrency(0, locale, currency)}</Text>
                        </Box>
                    </Card>
                </InlineGrid>
                <Box paddingBlockStart="400">
                    <Text as="p">Select Version</Text>
                    <LegacyStack>
                        <LegacyStack.Item fill>
                        <Box>
                            <BlockStack gap="300">
                                <RadioButton
                                    label="Version A (50%)"
                                    checked={version === 'A'}
                                    id="A"
                                    name="abversion"
                                    onChange={handleVersionChange}
                                />
                                <RadioButton
                                    label="Version B (50%)"
                                    id="B"
                                    name="abversion"
                                    checked={version === 'B'}
                                    onChange={handleVersionChange}
                                />
                            </BlockStack>
                        </Box>
                        </LegacyStack.Item>
                        <LegacyStack.Item>
                        <Box>
                        <BlockStack gap="300">
                        <Modal
                                activator={activatorA}
                                open={activeA}
                                onClose={handleAChange}
                                title="Remove version"
                                primaryAction={{
                                content: 'Remove version',
                                onAction: removeVersionA,
                                destructive: true,
                                }}
                                secondaryActions={[
                                {
                                    content: 'Cancel',
                                    onAction: handleAChange,
                                },
                                ]}
                            >
                                <Modal.Section>
                                <TextContainer>
                                    <p>
                                    This will remove all analytics associated. This cannot be undone.
                                    </p>
                                </TextContainer>
                                </Modal.Section>
                            </Modal>
                            <Modal
                                    activator={activatorB}
                                    open={activeB}
                                    onClose={handleBChange}
                                    title="Remove version"
                                    primaryAction={{
                                    content: 'Remove version',
                                    onAction: removeVersionB,
                                    destructive: true,
                                    }}
                                    secondaryActions={[
                                    {
                                        content: 'Cancel',
                                        onAction: handleBChange,
                                    },
                                    ]}
                                >
                                    <Modal.Section>
                                    <TextContainer>
                                        <p>
                                        This will remove all analytics associated. This cannot be undone.
                                        </p>
                                    </TextContainer>
                                    </Modal.Section>
                                </Modal>
                            </BlockStack>
                        </Box>
                        </LegacyStack.Item>
                    </LegacyStack>
                </Box>
                </Box>
            </LegacyCard>
          :
          ((("manual"===checkoutOfferType && Array.isArray(state.product)) || "ai"===checkoutOfferType) && 'drawer' !== state.cartType) ?  
            <LegacyCard sectioned>
                <div
                onClick={() => setABTestingEdit(false)}
                >
                <LegacyStack alignment="center">
                    <LegacyStack.Item fill>
                    <Text as="h2" variant="headingMd">A/B Version</Text>
                    <Text tone="subdued">
                        Impressions, Conversion Rate, Revenue
                    </Text>
                    </LegacyStack.Item>
                    <LegacyStack.Item>
                    <Icon source={ChevronUpIcon} />
                    </LegacyStack.Item>
                </LegacyStack>
                </div>
                <CardDivider/>
                <ButtonWrapper>
                    <Button variant="primary" onClick={()=>enableABTesting()}>Add an A/B version</Button>
                </ButtonWrapper>
            </LegacyCard>
            :
            false
        ):
        (
            <LegacyCard sectioned>
                <div
                onClick={() => setABTestingEdit(true)}
                >
                <LegacyStack alignment="center">
                    <LegacyStack.Item fill>
                    <Text as="h2" variant="headingMd">A/B Version</Text>
                    <Text tone="subdued">
                        Impressions, Conversion Rate, Revenue
                    </Text>
                    </LegacyStack.Item>
                    <LegacyStack.Item>
                    <Icon source={ChevronDownIcon} />
                    </LegacyStack.Item>
                </LegacyStack>
                </div>
            </LegacyCard>

        )
    }
    </Box> 
            
    )
}
export default ABTesting;